import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/redux/Store';
import { Link } from 'react-router-dom';
import { opperetPaths } from '../../routing/opperetRoutes';
import { KTSVG } from '../../../_metronic/helpers';
import { dateUnity } from '../../Utilities';

const CurrentStudyInfo: React.FC = () => {
  const { currentStudy, isStudyLoading, error } = useSelector(
    (state: RootState) => state.opperetReducer
  );

  if (isStudyLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body d-flex flex-center py-10'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Chargement...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-10'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <KTSVG
              path='/media/icons/duotone/Code/Warning-2.svg'
              className='svg-icon-2hx svg-icon-danger me-4'
            />
            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-danger'>Erreur</h4>
              <span>{error}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!currentStudy) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-10'>
          <div className='alert alert-primary d-flex align-items-center p-5'>
            <KTSVG
              path='/media/icons/duotone/Code/Info-circle.svg'
              className='svg-icon-2hx svg-icon-primary me-4'
            />
            <div className='d-flex flex-column flex-grow-1'>
              <h4 className='mb-1 text-primary'>Aucune étude sélectionnée</h4>
              <span>Veuillez sélectionner une étude pour commencer</span>
            </div>
            <Link
              to={opperetPaths.studies.list.path}
              className='btn btn-sm btn-primary'
            >
              <KTSVG
                path='/media/icons/duotone/Navigation/Plus.svg'
                className='svg-icon-2'
              />
              Sélectionner une étude
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        
        <div className='card-toolbar'>
          <Link
            to={opperetPaths.studies.list.path}
            className='btn btn-sm btn-light-primary'
          >
            <KTSVG
              path='/media/icons/duotone/Navigation/Arrow-from-left.svg'
              className='svg-icon-3'
            />
            Changer d'étude
          </Link>
        </div>
      </div>

      <div className='card-body py-0'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered table-row-dashed gy-5'>
            <tbody className='fs-6 fw-semibold'>
              <tr>
                <td className='text-gray-800 w-175px'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotone/Design/Component.svg'
                      className='svg-icon-2 me-2'
                    />
                    Etude
                  </div>
                </td>
                <td>
                  <span className='badge badge-light-primary fs-7 fw-bold'>
                    {currentStudy.designation}
                  </span>
                </td>
              </tr>

              <tr>
                <td className='text-gray-800'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotone/Code/Time-schedule.svg'
                      className='svg-icon-2 me-2'
                    />
                    Date
                  </div>
                </td>
                <td>
                  <span className='badge badge-light-info fs-7 fw-bold'>
                    {new Date(currentStudy.date).toLocaleDateString()}
                  </span>
                </td>
              </tr>

              <tr>
                <td className='text-gray-800'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotone/Files/Folder.svg'
                      className='svg-icon-2 me-2'
                    />
                    Famille
                  </div>
                </td>
                <td className='text-gray-800'>
                  {currentStudy.family?.name}
                </td>
              </tr>

              <tr>
                <td className='text-gray-800'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotone/General/User.svg'
                      className='svg-icon-2 me-2'
                    />
                    Age
                  </div>
                </td>
                <td className='text-gray-800'>
                  {currentStudy.family_age} {dateUnity(currentStudy.unity_family_age)}
                </td>
              </tr>

              {currentStudy.description && (
                <tr>
                  <td className='text-gray-800'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotone/Text/Text-width.svg'
                        className='svg-icon-2 me-2'
                      />
                      Description
                    </div>
                  </td>
                  <td className='text-gray-800'>
                    {currentStudy.description}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CurrentStudyInfo;