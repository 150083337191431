import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class CriterionModel extends Model {
    private prefix = "opperet/criterions"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getAll(params: any) {
        let path = this.prefix
        return this.withParams(path, params)
    }

    getByStudy(studyId: number, params: any) {
        return this.withParams(`${this.prefix}/by-study/${studyId}`, params)
    }

    add(data: {}) {
        return this.post(this.prefix + `/save-add`, data)
    }

    find(id: bigint) {
        return this.get(this.prefix + `/find/${id}`)
    }

    update(id: bigint, data: {}) {
       // console.log(data)
        return this.post(this.prefix + `/save-edit/${id}`, data)
        
    }

    delete(id: bigint) {
        return this.get(this.prefix + `/delete/${id}`)
    }
}

export default CriterionModel