import { Redirect, Route, Switch } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { opperetPaths } from "../../../routing/opperetRoutes"
import StudyList from "./StudyList"

const StudyPage = () => {
    const breadCrumbs: Array<PageLink> = [
        /*{
            title: "",
            path: opperetPaths.studies.list.path,
            isSeparator: false,
            isActive: false,
        }*/
    ]
    return (
        <Switch>
            <Route path={opperetPaths.studies.list.path} exact >
                <PageTitle defaultTitle={"ETUDES"} breadcrumbs={breadCrumbs}>
                    {opperetPaths.studies.list.title}
                </PageTitle>
                <StudyList className='mb-5 mb-xl-8' />
            </Route>
            {/* Autres routes du module Opperet */}
            <Redirect to='/error/404' />
        </Switch>
    )
}

export default StudyPage

