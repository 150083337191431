import { lazy } from 'react'
import { Redirect, Route, Switch } from "react-router-dom"

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { opperetPaths } from '../../../routing/opperetRoutes'
import RatingForm from './RatingForm' // Changé de { RatingForm } à RatingForm


const RatingPage = () => {
    const breadCrumbs: Array<PageLink> = [
        /*{
            title: "",
            path: opperetPaths.ratings.save.path,
            isSeparator: false,
            isActive: false,
        }*/
    ]
    return (
        <Switch>
            <Route path={opperetPaths.ratings.save.path} exact >
                <PageTitle defaultTitle={"NOTATIONS"} breadcrumbs={breadCrumbs}>
                    {opperetPaths.ratings.save.title}
                </PageTitle>
                <RatingForm className='mb-5 mb-xl-8' />
            </Route>
            {/* Autres routes du module Opperet */}
            <Redirect to='/error/404' />
        </Switch>
    )
}

export default RatingPage

