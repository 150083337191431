import { Link } from "react-router-dom"
import { opperetPaths } from "../../routing/opperetRoutes"
import CurrentStudyInfo from "./CurrentStudyInfo"

const OpperetHeader = () => {
    const currentPath = window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);

    return (
        <>
            <CurrentStudyInfo />
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                    <div className='d-flex overflow-auto h-55px'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                            <li className='nav-item'>
                                <Link
                                    to={opperetPaths.criterions.list.path}
                                    className={
                                        `nav-link text-active-orange me-6 ` +
                                        (currentPath.includes(opperetPaths.criterions.root) && 'active')
                                    }
                                >
                                    {opperetPaths.criterions.list.title}
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    to={opperetPaths.ratings.save.path}
                                    className={
                                        `nav-link text-active-orange me-6 ` +
                                        (currentPath.includes(opperetPaths.ratings.root) && 'active')
                                    }
                                >
                                    {opperetPaths.ratings.save.title}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OpperetHeader