import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup/redux/Store'
import { KTSVG } from '../../../../_metronic/helpers'
import { RatingCalculator, Criterion, Instrument, legendItems, getHiddenInputValue, exportRatingToExcel } from './RatingCalculator'
import FamilyModel from '../../../../models/Opperet/FamilyModel'
import RatingModel from '../../../../models/Opperet/RatingModel'
import { clsx, consoleLog } from '../../../Utilities'
import { notify } from '../../../../services/Toastify/Toastify'
import { opperetPaths } from '../../../routing/opperetRoutes'
import CriterionModel from '../../../../models/Opperet/CriterionModel'


const RatingForm: React.FC<{ className: string }> = ({ className }) => {
  // États
  const [loadingData, setLoadingData] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [criterions, setCriterions] = useState<Criterion[]>([])
  const [instruments, setInstruments] = useState<Instrument[]>([])
  const [scores, setScores] = useState<Record<string, Record<string, any>>>({})
  const [calculator, setCalculator] = useState<RatingCalculator | null>(null)

  // Récupération du currentStudy depuis le store
  const { currentStudy } = useSelector((state: RootState) => state.opperetReducer)
  
  // Instances des modèles
  const familyModel = new FamilyModel()
  const criterionModel = new CriterionModel();
  const ratingModel = new RatingModel()

  useEffect(() => {
    if (currentStudy?.family?.id) {
      fetchData(currentStudy)
    }
  }, [currentStudy])

  useEffect(() => {
    if (criterions.length && instruments.length && scores && currentStudy) {
      setCalculator(new RatingCalculator(
        criterions, 
        instruments, 
        scores, 
        currentStudy.family_age
      ))
    }
  }, [criterions, instruments, scores, currentStudy])

  const fetchData = async (currentStudy: any) => {
    setLoadingData(true)

    try {
      await fetchCriterionsScoresAndInstruments(currentStudy)
    } catch (error) {
      consoleLog.error('Error fetching family data:', error)
    } finally {
      setLoadingData(false)
    }
  }

  const fetchCriterionsScoresAndInstruments = async (currentStudy: any) => {
    //consoleLog('currentStudy', currentStudy)
    const [criterionResponse, instrumentsResponse, scoresResponse] = await Promise.all([
      criterionModel.getByStudy(Number(currentStudy?.id), { per_page: 'all' }),
      familyModel.getInstrumentsByFamily(currentStudy?.family?.id, { per_page: 'all' }),
      ratingModel.getByStudy(Number(currentStudy?.id), { per_page: 'all' })
    ])

    const fetchedCriterions = criterionResponse.data?.result?.data || []
    //consoleLog('criterions',fetchedCriterions)
    
    let fetchedInstruments = instrumentsResponse.data?.result?.data?.map((instrument: any) => ({
      id: instrument.id,
      design_mat: instrument.design_mat,
      code_mat: instrument.code_mat,
      calibration_frequency: instrument.frequence_racc_mat
    })) || []

    //consoleLog(instrumentsResponse)

    // Si des scores existent, filtrer les instruments pour ne garder que ceux avec des scores
    const existingScores = scoresResponse.data?.result?.data || []
    if (existingScores.length > 0) {
      // Create a Set using Array.from instead of spread operator
      const instrumentIdsWithScores = Array.from(
        new Set(existingScores.map((score: any) => score.gmo_instrument_id))
      );
      fetchedInstruments = fetchedInstruments.filter((instrument: any) => 
        instrumentIdsWithScores.includes(instrument.id)
      )
    }

    const initialScores: Record<string, Record<string, any>> = {}
    fetchedInstruments.forEach((instrument: any) => {
      initialScores[instrument.id] = {}
      fetchedCriterions.forEach((criterion: any) => {
        const existingScore = existingScores.find(
          (score: any) => score.gmo_instrument_id === instrument.id && score.gmo_criterion_id === criterion.id
        )
        initialScores[instrument.id][criterion.id] = existingScore?.score 
      })
    })
    //consoleLog(fetchedCriterions, fetchedInstruments, initialScores)
    setCriterions(fetchedCriterions)
    setInstruments(fetchedInstruments)
    setScores(initialScores)
  }

  const handleScoreChange = (instrumentId: string, criterionId: string, value: string) => {
    setScores(prevScores => ({
      ...prevScores,
      [instrumentId]: {
        ...prevScores[instrumentId],
        [criterionId]: value || '' // Conversion des valeurs falsy en chaîne vide
      }
    }))
  }

  const checkAllSelectsFilled = () => {
    const selectElements = document.querySelectorAll('select.score-select')
    const areAllFilled = Array.from(selectElements).every(select => {
      const value = (select as HTMLSelectElement).value
      return value !== undefined && value !== null && value !== ''
    })
    return areAllFilled;
    //setAllSelectsFilled(areAllFilled)
  }

  const handleSubmit = async () => {
    if (!currentStudy?.family?.id || !calculator) {
      notify('error', 'Données de l\'étude non disponibles');
      return;
    }

   /* if (!checkAllSelectsFilled()) {
      notify('error', 'Veuillez saisir toutes les notes avant de soumettre');
      return;
    }*/

    setSubmitting(true);

    try {
      const scoresData: any[] = [];
      const calculsData: any[] = [];

      Object.entries(scores).forEach(([instrumentId, criterionScores]) => {
        const ng = Number(getHiddenInputValue(`calculations[${instrumentId}][ng]`));
        const eng = Number(getHiddenInputValue(`calculations[${instrumentId}][eng]`));
        const factorS = Number(getHiddenInputValue(`calculations[${instrumentId}][factor_s]`));
        const opperetCorrection = Number(getHiddenInputValue(`calculations[${instrumentId}][opperet_correction]`));
        const optimizedFrequency = Number(getHiddenInputValue(`calculations[${instrumentId}][optimized_frequency]`));

        calculsData.push({
          gmo_study_id: currentStudy.id,
          gmo_instrument_id: instrumentId,
          ng,
          eng,
          factor_s: factorS,
          opperet_correction: opperetCorrection,
          optimized_frequency: optimizedFrequency
        });

        Object.entries(criterionScores).forEach(([criterionId, score]) => {
          const enc = Number(getHiddenInputValue(`calculations[${instrumentId}][criterions][${criterionId}][enc]`));

          scoresData.push({
            gmo_study_id: currentStudy.id,
            gmo_instrument_id: instrumentId,
            gmo_criterion_id: criterionId,
            score: score === '' ? null : (isNaN(Number(score)) ? null : Number(score)),
            enc
          });
        });
      });

      const saveData = { ratings: scoresData, calculs: calculsData };
     // consoleLog(saveData)
     /**/ const response = await ratingModel.save(saveData);
      
      if (response.data.type && response.data.message) {
        notify(response.data.type, response.data.message);
      }
    } catch (error) {
      consoleLog('Error saving scores:', error);
      notify('error', 'Une erreur est survenue lors de la sauvegarde');
    } finally {
      setSubmitting(false);
    }
  };


  const handleExportRatingsToExcel = () => {
    if (!criterions.length || !instruments.length) return;
    /*if (!checkAllSelectsFilled()) {
      notify('error', 'Veuillez saisir toutes les notes avant de soumettre');
      return;
    }*/
    exportRatingToExcel(criterions, instruments, scores);
  }
  

  const renderTable = () => {
    // Vérification du chargement et des données requises
    if (loadingData) {
      return (
        <div className='d-flex justify-content-center align-items-center min-h-300px'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Chargement...</span>
          </div>
        </div>
      )
    }

    // Vérification que toutes les données nécessaires sont présentes
    if (!calculator || !criterions.length || !instruments.length) {
      return (
        <div className='alert alert-warning d-flex align-items-center p-5 mb-10'>
          <span className='svg-icon svg-icon-2hx svg-icon-warning me-4'></span>
          <div className='d-flex flex-column'>
            <h4 className='mb-1 text-warning'>Aucune donnée disponible</h4>
            <span>Veuillez vérifier que l'étude contient des critères et des instruments.</span>
          </div>
        </div>
      )
    }

    // Rendu du tableau si toutes les conditions sont remplies
    return (
      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 gy-5'>
          <thead>
            <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
              <th className='min-w-200px text-center bg-light-primary border' rowSpan={2}>
                {legendItems[0].abbr}
              </th>
              <th className='text-center bg-light-primary border' colSpan={criterions.length}>
                {legendItems[1].abbr}
              </th>
              <th className='text-center bg-light-primary border' colSpan={criterions.length}>
                {legendItems[2].abbr}
              </th>
              <th className='min-w-100px text-center bg-light-primary border' rowSpan={2}>
                {legendItems[3].abbr}
              </th>
              <th className='min-w-100px text-center bg-light-primary border' rowSpan={2}>
                {legendItems[4].abbr}
              </th>
              <th className='min-w-100px text-center bg-light-primary border' rowSpan={2}>
                {legendItems[5].abbr}
              </th>
              <th className='min-w-100px text-center bg-light-primary border' rowSpan={2}>
                {legendItems[6].abbr}
              </th>
              <th className='min-w-100px text-center bg-light-primary border' rowSpan={2}>
                {legendItems[7].abbr}
              </th>
            </tr>
            <tr className='fw-bold fs-6 text-gray-800'>
              {criterions.map((criterion: any) => (
                <th key={`criterion-${criterion.id}`} className='min-w-100px text-center bg-light-primary border'>
                  {criterion.name}
                </th>
              ))}
              {criterions.map((criterion: any) => (
                <th key={`enc-${criterion.id}`} className='min-w-100px text-center bg-light-primary border'>
                  {criterion.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {instruments.map((instrument, idx) => {
              const instrumentCalcs = calculator.getInstrumentCalculations(instrument.id)
              const { ng, eng, factorS, opperet, optimizedFrequency } = instrumentCalcs

              return (
                <tr key={instrument.id} className={`border-bottom border-gray-200 ${idx % 2 === 0 ? 'bg-light-secondary' : ''}`}>
                  <td className='fw-bold fs-6 text-gray-800 border'>
                    {instrument.code_mat ? instrument.design_mat + ' (' + instrument.code_mat + ')' : instrument.design_mat}
                  </td>

                  {/* Colonnes des critères */}
                  {criterions.map(criterion => (
                    <td key={`score-${criterion.id}`} className='border text-center'>
                      <select
                        className='form-select score-select form-select-solid form-select-sm'
                        value={getScoreValue(scores, instrument.id, criterion.id)}
                        onChange={(e) => handleScoreChange(instrument.id, criterion.id, e.target.value)}
                      >
                       <option value="">--</option>  
                        {criterion?.weightings?.map((weighting: any) => (
                          <option key={weighting.id} value={weighting.value}>
                            {weighting.value} | {weighting.name}
                          </option>
                        ))}
                      </select>
                    </td>
                  ))}

                  {/* Colonnes ENC */}
                  {criterions.map(criterion => {
                    const { enc } = calculator.getCriterionCalculations(instrument.id, criterion.id)
                    return (
                      <td key={`enc-${criterion.id}`} className='border text-center'>
                        <span className='fw-bold'>{enc}</span>
                        <input
                          type="hidden"
                          name={`calculations[${instrument.id}][criterions][${criterion.id}][enc]`}
                          value={enc}
                        />
                      </td>
                    )
                  })}

                  {/* Colonnes des calculs */}
                  <td className='border text-center'>
                    <span className='fw-bold'>{ng}</span>
                    <input
                      type="hidden"
                      name={`calculations[${instrument.id}][ng]`}
                      value={ng}
                    />
                  </td>
                  <td className='border text-center'>
                    <span className='fw-bold'>{eng}</span>
                    <input
                      type="hidden"
                      name={`calculations[${instrument.id}][eng]`}
                      value={eng}
                    />
                  </td>
                  <td className='border text-center'>
                    <span className='fw-bold'>{factorS}</span>
                    <input
                      type="hidden"
                      name={`calculations[${instrument.id}][factor_s]`}
                      value={factorS}
                    />
                  </td>
                  <td className='border text-center'>
                    <span className='fw-bold'>{opperet}</span>
                    <input
                      type="hidden"
                      name={`calculations[${instrument.id}][opperet_correction]`}
                      value={opperet}
                    />
                  </td>
                  <td className='border text-center'>
                    <span className='fw-bold'>{optimizedFrequency}</span>
                    <input
                      type="hidden"
                      name={`calculations[${instrument.id}][optimized_frequency]`}
                      value={optimizedFrequency}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const getScoreValue = (scores: any, instrumentId: string, criterionId: string): string => {
    const score = scores[instrumentId]?.[criterionId];
    if (score === null) return '';
    if (score === 0) return '0';
    return score?.toString() ?? '';
  }

  return (
    <div className={`card ${className}`}>
      

      <div className='card-body'>
        <div className='alert alert-primary d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column'>
            <h4 className='mb-1 text-primary fs-6'>Légende</h4>
            <div className='mb-0'>
              <ul className='list-unstyled mt-2 mb-0 fs-7'>
                {Object.entries(legendItems).map(([key, item]) => (
                  <li key={key} className='list-item d-flex align-items-center mb-1'>
                    <span className='text-gray-700'>
                      <span className='fw-bold'>{item.abbr}</span>
                      {' - '}
                      <span className='fs-8'>{item.full}</span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {renderTable()}
        {/* Boutons déplacés ici */}
        <div className='d-flex justify-content-end mt-5'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleSubmit}
            disabled={loadingData || submitting}
          >
            {submitting ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Enregistrement...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              'Enregistrer'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default RatingForm
