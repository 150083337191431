/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import parse from 'html-react-parser'
import {CheckAccess, currentTime} from '../../../../Utilities'
import {getStartAndEndDates} from '../../../../../services/DateProtoType'
import DataTable from '../../../../../services/Table/DataTable'
import {ExportToExcelRaccordementNearOrOutDate} from '../../../../../services/Excel/ExportToExcel'
import DashBoardModel from '../../../../../models/gp/DashBoardModel'
import FilterByDateComponent from './FilterByDateComponent'

type Props = {
  className: string
  cdi: any
}

const RaccordementsBoard: React.FC<Props> = ({className, cdi}) => {
  const {startOfYear, endOfYear} = getStartAndEndDates()
  const [filters, setFilters] = useState({from: startOfYear, to: endOfYear})
  const [loading, setLoading] = useState(false)
  // const [tableReload, setTableReload] = useState(true)
  const [tableReload, setTableReload] = useState(currentTime)

  const onSearch = () => {
    // @ts-ignore
    let from = document.getElementById('from').value
    // @ts-ignore
    let to = document.getElementById('to').value

    // console.log(from, to)
    setFilters({from: from, to: to})

    setTableReload(currentTime)
  }

  const columns = [
    {
      dataField: 'materiel.design_mat',
      text: 'Matériels',
      sort: true,
      search: {},
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <>{row.materiel_design_mat}</>
      },
    },
    {
      dataField: 'materiel.code_mat',
      text: 'Code',
      sort: true,
      search: {},
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <>{row.materiel_code_mat}</>
      },
    },
    {
      dataField: 'metrology_cell.name',
      text: 'Cellule appartenance',
      sort: true,
      search: {},
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <>{row?.metrology_cell_name}</>
      },
    },
    {
      dataField: 'date_rac_next',
      text: 'Date prochaine rac.',
      sort: true,
      search: {},
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <>{parse(row.date_rac_next_span)}</>
      },
    },
    {
      dataField: 'domaine.design_dom',
      text: 'Domaines',
      sort: true,
      search: {},
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <>{row.domaine_design_dom}</>
      },
    },
  ]

  const export1 = () => {
    ;(async () => {
      await ExportToExcelRaccordementNearOrOutDate(filters)
    })()
  }

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-2 mb-1'>
              Tableau des équipements dont le raccordement est proche ou dépassé
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
          >
            {CheckAccess('dashboard-access') && (
              <a href={void 0} onClick={() => export1()} className='btn btn-sm btn-light-success'>
                <i className='fas fa-file-excel'></i>
                Exporter
              </a>
            )}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* <FilterByDateComponent
            from={filters?.from}
            to={filters?.to}
            onFilterChange={handleFilterChange}
            onApply={handleApplyFilters}
            isLoading={false}
          /> */}

          <div className='row mb-4'>
            {/* begin::Form group */}
            <div className='col-lg-4'>
              <label className='form-label'>
                <span className=''>Date de debut</span>
              </label>
              <input
                id='from'
                type='date'
                name='from'
                defaultValue={startOfYear}
                className={'form-control form-control-lg form-control-solid'}
              />
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='col-lg-4'>
              <label className='form-label'>
                <span className=''>Date de fin</span>
              </label>
              <input
                id='to'
                type='date'
                name='to'
                defaultValue={endOfYear}
                className={'form-control form-control-lg form-control-solid'}
              />
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='col-lg-4 mt-9'>
              <button
                type='button'
                onClick={() => onSearch()}
                className='btn btn-orange w-100 mb-5'
              >
                {!loading && <span className='indicator-label text-uppercase'>Rechercher</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-binvoice spinner-binvoice-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </div>

          {/* begin::Table container */}
          <div className='table-responsive'>
            <DataTable
              tableReload={tableReload}
              dataModel={new DashBoardModel()}
              columns={columns}
              path='/component/lists/raccordements/near-out-date'
              defaultParams={filters}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {RaccordementsBoard}
