import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class RatingModel extends Model {
    private prefix = "opperet/ratings"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getAll(params: any) {
        let path = this.prefix
        return this.withParams(path, params)
    }

    getByStudy(studyId: number, params: any) {
        return this.withParams(`${this.prefix}/by-study/${studyId}`, params)
    }

    getByInstrument(instrumentId: string) {
        return this.get(`${this.prefix}/by-instrument/${instrumentId}`)
    }

    getByCriteria(criteriaId: string) {
        return this.get(`${this.prefix}/by-criteria/${criteriaId}`)
    }

    getByFamily(familyId: string) {
        return this.get(`${this.prefix}/by-family/${familyId}`)
    }

    save(data:{}) {
        return this.post(`${this.prefix}/save`, data)
    }

    add(data: {}) {
        return this.post(this.prefix + `/save-add`, data)
    }

    find(id: bigint) {
        return this.get(this.prefix + `/find/${id}`)
    }

    update(id: bigint, data: {}) {
        return this.post(this.prefix + `/save-edit/${id}`, data)
    }

    

    delete(id: bigint) {
        return this.get(this.prefix + `/delete/${id}`)
    }
}

export default RatingModel;
