
//import {CheckAccess} from '../../../../app/Utilities';

import { AsideMenuItem } from "../../../_metronic/layout/components/aside/AsideMenuItem"
import { AsideMenuItemWithSub } from "../../../_metronic/layout/components/aside/AsideMenuItemWithSub"
import { opperetPaths, pathOpperetModule } from "../../routing/opperetRoutes"

//AsideMenuItemWithSub
const AsideMenuOpperet = () => {
    return (
        <>
        <AsideMenuItem
          //modulePath={pathOpperetModule}
          to={opperetPaths.studies.list.path}
          icon='/media/icons/duotone/Home/Building.svg'
          title='Etude Opperet'
          fontIcon='bi-study'
         // hasBullet={true}
          method={'opperet-access'}
        />
        </>
    )
}
export default AsideMenuOpperet
