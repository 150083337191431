import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useFormik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import CriterionModel from '../../../../models/Opperet/CriterionModel';
import FamilyModel from '../../../../models/Opperet/FamilyModel';
import WeightingModel from '../../../../models/Opperet/WeithingModel';
import { notify } from '../../../../services/Toastify/Toastify';
import { opperetPaths } from '../../../routing/opperetRoutes';

import {
  MaterielFamille,
  GmoCriterion,
  GmoWeighting,
  plots
} from '../../../../types/gmo';
import store from '../../../../setup/redux/Store';
import StudyModel from '../../../../models/Opperet/StudyModel';
import { consoleLog } from '../../../Utilities';

type ParamsType = { id?: string };

interface Props {
  className: string;
}



const CriterionForm: React.FC<Props> = ({ className }) => {
  const { currentStudy } = store.getState().opperetReducer;
  const { id } = useParams<ParamsType>();
  const history = useHistory();
  const initialWeighting: GmoWeighting = {
    name: '',
    value: 0,
    gmo_criterion_id: 0,
    gmo_study_id: currentStudy?.id || null,
  };

  const [selectedPlot, setSelectedPlot] = useState<number>(0);
  const [familyMismatch, setFamilyMismatch] = useState<boolean>(false);

  const criterionModel = new CriterionModel();
  const familyModel = new FamilyModel();
  const weightingModel = new WeightingModel();
  const studyModel = new StudyModel();

  const [family, setFamily] = useState<MaterielFamille | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const initialValues: GmoCriterion = {
    name: '',
    weight: 1,
    description: '', // Changer null en chaîne vide
    materiel_famille_id: null,
    gmo_study_id: null,
    weightings: [initialWeighting]
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Le nom est requis'),
    weight: Yup.number()
      .required('La pondération est requise'),
    materiel_famille_id: Yup.number()
      .required('La famille est requise')
      .min(1, 'Veuillez sélectionner une famille'),
    description: Yup.string().nullable(),
    weightings: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Le nom de la pondération est requis'),
        value: Yup.number()
          .required('La valeur de la pondération est requise')
      })
    )
  });

  const formik = useFormik<GmoCriterion>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);
        const response = id
          ? await criterionModel.update(BigInt(id), values)
          : await criterionModel.add(values);

        if (response?.data?.type !== 'success') {
          notify('error', response?.data?.message || 'Une erreur est survenue');
          return;
        }

        notify('success', `Critère ${id ? 'modifié' : 'créé'} avec succès`);

        if (!id) {
          formik.resetForm();
        }
//console.log(values)
        history.push(opperetPaths.criterions.list.path);
      } catch (error) {
        console.error('Erreur lors de la sauvegarde:', error);
        notify('error', 'Erreur lors de la sauvegarde du critère');
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    }
  });

  const handlePlotChange = async (plotRef: number) => {
    setSelectedPlot(plotRef);
    const selectedPlotData = plots.find(p => p.ref === plotRef);


    try {
      let newWeightings;

      if (selectedPlotData) {
        // Cas où un plot est sélectionné
        const plotValues = selectedPlotData.plot.split(',').map(Number);
        newWeightings = plotValues.map(value => ({
          ...initialWeighting,
          value: value
        }));
      } else if (id) {
        // Cas où aucun plot n'est sélectionné et on est en mode édition
        const weightingsResponse = await weightingModel.getByCriterion(Number(id));
        const weightingsData = weightingsResponse.data?.result;
        newWeightings = weightingsData?.map((weighting: any) => ({
          name: weighting.name,
          value: weighting.value,
          gmo_criterion_id: weighting.gmo_criterion_id
        })) || [initialWeighting];
      } else {
        // Cas par défaut : nouveau critère sans plot sélectionné
        newWeightings = [initialWeighting];
      }

      formik.setFieldValue('weightings', newWeightings);
    } catch (error) {
      console.error('Erreur lors de la mise à jour des pondérations:', error);
      formik.setFieldValue('weightings', [initialWeighting]);
      notify('error', 'Erreur lors de la récupération des pondérations');
    }
  };

  const getWeightingError = (index: number, field: any) => {
    return formik.errors?.weightings && formik.errors?.weightings[index] && formik.errors?.weightings[index][field];
  };

  const handleWeightingChange = (index: number, field: keyof GmoWeighting, value: string | number) => {
    const weightings = [...formik.values?.weightings || []];
    weightings[index] = {
      ...weightings[index],
      [field]: value
    };
    formik.setFieldValue('weightings', weightings);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const gmoStudy = store.getState().opperetReducer.currentStudy;
      
      setFamily({
        id: gmoStudy?.family?.id || 0,
        name: gmoStudy?.family?.name || ''
      });

      if (id) {
        const criterionResponse = await criterionModel.find(BigInt(id));
        const criterionData = criterionResponse.data?.result;
        
        // Vérifier si la famille du critère correspond à celle de l'étude
        if (criterionData?.materiel_famille_id != gmoStudy?.materiel_famille_id) {
          setFamilyMismatch(true);
          notify('warning', 'La famille du critère ne correspond pas à celle de l\'étude');
        }

        const mappedWeightings = criterionData?.weightings?.map((weighting: GmoWeighting) => ({
          name: weighting.name,
          value: weighting.value,
          gmo_criterion_id: weighting.gmo_criterion_id,
          gmo_study_id: weighting.gmo_study_id
        })) || [];

        if (criterionData) {
          formik.setValues({
            name: criterionData.name,
            weight: criterionData.weight,
            description: criterionData.description || '',
            materiel_famille_id: criterionData.materiel_famille_id,
            gmo_study_id: criterionData.gmo_study_id || currentStudy?.id,
            weightings: mappedWeightings || [initialWeighting]
          });
        }
      } else {
        formik.setValues({
          ...initialValues,
          materiel_famille_id: gmoStudy?.materiel_famille_id || null,
          gmo_study_id: currentStudy?.id || null,
        });
      }
    } catch (error) {
      console.error('Erreur lors du chargement des données:', error);
      setError('Erreur lors du chargement des données');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={`card ${className}`}>
        {familyMismatch && (
          <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
            <span className="svg-icon svg-icon-2hx svg-icon-warning me-4">
              <i className="bi bi-exclamation-triangle fs-2"></i>
            </span>
            <div className="d-flex flex-column">
              <h4 className="mb-1 text-warning">Attention</h4>
              <span>La famille du critère ne correspond pas à celle de l'étude en cours</span>
            </div>
          </div>
        )}

        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-2 mb-1'>
              {id ? opperetPaths.criterions.edit.title : opperetPaths.criterions.add.title}
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} className='form w-100' noValidate>
            <div className='row mb-4'>
              <div className='col-lg-8'>
                <div className='row mb-4'>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label className='col-form-label'>
                        <span className='required'>Famille d'instruments</span>
                      </label>
                      
                      <input 
                        type='text' 
                        className='form-control form-control-lg form-control-solid' 
                        value={family?.name || ''} 
                        readOnly
                      />
                    
                      {formik.touched.materiel_famille_id && formik.errors.materiel_famille_id && (
                        <div className='invalid-feedback'>
                          <span role='alert'>{formik.errors.materiel_famille_id}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label className='col-form-label'>
                        <span className='required'>Désignation</span>
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('name')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.name && formik.errors.name },
                          { 'is-valid': formik.touched.name && !formik.errors.name }
                        )}
                        disabled={loading}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='invalid-feedback'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row mb-4'>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label className='col-form-label'>
                        <span className='required'>Pondération</span>
                      </label>
                      <input
                        type='number'
                        {...formik.getFieldProps('weight')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.weight && formik.errors.weight },
                          { 'is-valid': formik.touched.weight && !formik.errors.weight }
                        )}
                        disabled={loading}
                      />
                      {formik.touched.weight && formik.errors.weight && (
                        <div className='invalid-feedback'>
                          <span role='alert'>{formik.errors.weight}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label className='col-form-label'>Description</label>
                      <textarea
                        {...formik.getFieldProps('description')}
                        value={formik.values.description || ''} // Ajouter cette conversion
                        className='form-control form-control-lg form-control-solid'
                        rows={3}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='row mb-4'>
                  <div className='col-lg-12'>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      <h4 className='fw-bold text-dark'>Plots</h4>
                      <select
                        className='form-select form-select-solid w-200px'
                        value={selectedPlot}
                        onChange={(e) => handlePlotChange(Number(e.target.value))}
                        disabled={loading}
                      >
                        <option value="0">Sélectionner un plot</option>
                        {plots.map((plot) => (
                          <option key={plot.ref} value={plot.ref}>
                            Plot {plot.ref} ({plot.plot})
                          </option>
                        ))}
                      </select>
                    </div>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                        <th>Valeur</th>
                          <th>Intitulé</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values?.weightings && formik.values.weightings.map((weighting, index) => (
                          <tr key={index}>
                            
                            <td>
                              <span className='badge badge-light-primary fs-7 fw-bold'>
                                {weighting.value}
                              </span>
                            </td>
                            <td>
                              <input
                                type='text'
                                value={weighting.name}
                                onChange={(e) => handleWeightingChange(index, 'name', e.target.value)}
                                className={clsx(
                                  'form-control form-control-lg form-control-solid',
                                  { 'is-invalid': getWeightingError(index, 'name') }
                                )}
                                disabled={loading}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>





            {/* begin::Action */}
            <div className='text-center mt-8'>
              <button
                type='submit'
                disabled={loading || !formik.isValid || formik.isSubmitting}
                className='btn btn-lg btn-primary w-100 mb-5'
              >
                {!loading && <span className='indicator-label text-uppercase'>Enregistrer</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Veuillez patienter...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export default CriterionForm;
