import { useEffect } from "react";

const useRemoveIframe = () => {
  useEffect(() => {
    const removeIframe = () => {
      document.querySelectorAll("iframe").forEach((iframe) => {
        if (iframe.style.zIndex === "2147483647") {
          iframe.remove();
        }
      });
    };

    removeIframe(); // Supprime immédiatement
    const interval = setInterval(removeIframe, 500); // Vérifie toutes les 500ms

    return () => clearInterval(interval);
  }, []);
};

export default useRemoveIframe;