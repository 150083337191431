import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'

import {MenuItem} from './MenuItem'

const appType = {
  title: '',
  to: ''
}

export function MenuInner() {
  const currentApp = useSelector((state: RootState) => state.appReducer.currentApp, shallowEqual)

  if(currentApp == "GP") {
    appType.title = "Gestion parc instruments"
    appType.to = "instrument/dashboard"
  }
  
  return (
    <>
    
      <MenuItem currentApp="GP" title="Gestion parc instruments" to='/instrument/dashboard' />
      
    </>
  )
}
