import {lazy} from 'react'
import { Redirect, Route, Switch } from "react-router-dom"
import RatingPage from '../opperet/pages/rating/RatingPage'
import StudyPage from '../opperet/pages/study/StudyPage'
import OpperetPage from '../opperet/OpperetPage'
const CriterionPage = lazy(() => import('../opperet/pages/criterion/CriterionPage'))

type Props = {
  pathModule: string
}
export const pathOpperetModule = '/opperet'

export const opperetPaths = {
  'studies' : {

    'root' : pathOpperetModule + '/studies',
    'list' : {
      title : 'Liste des études',
      path : pathOpperetModule + '/studies/list'
    },
    'add' : {
      title : 'Ajouter une étude',
      path : pathOpperetModule + '/studies/add'
    },
    'edit' : {
      title : 'Modifier une étude',
      path : pathOpperetModule + '/studies/edit/:id'
    }
  },
  'criterions' : {
    'root' : pathOpperetModule + '/criterions',
    'list' : {
      title : 'Barèmes de notations',
      path : pathOpperetModule + '/criterions/list'
    },
    'add' : {
      title : 'Ajouter un barème',
      path : pathOpperetModule + '/criterions/add'
    },
    'edit' : {
      title : 'Modifier un barème',
      path : pathOpperetModule + '/criterions/edit/:id'
    }
  },
  'ratings' : {
    'root' : pathOpperetModule + '/ratings',
    'save' : {
      title : 'Notations',
      path : pathOpperetModule + '/ratings/save'
    }
  }
}

const OpperetRoutes : React.FC = () => {
  return (
    <Switch>
      <Route path={opperetPaths.criterions.root + '/*'} render={(props) => <OpperetPage {...props} mode='tab'  />} exact/>
      <Route path={opperetPaths.ratings.root + '/*'} render={(props) => <OpperetPage {...props} mode='tab'  />} exact/>
      <Route path={opperetPaths.studies.root + '/*'} render={(props) => <OpperetPage  />} exact/>
      {/* Autres routes du module Opperet */}
    </Switch>
  )
}

export default OpperetRoutes

