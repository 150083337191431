import React, { useState, useRef, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { KTSVG } from '../../../../_metronic/helpers'
import * as columnFormatters from '../../../../services/Table/column-formatters'
import { SweetAlert } from '../../../../services/Sweetalert2/SweetAlert'
import { CheckAccess, responseApi, currentTime, consoleLog, dateUnity } from '../../../Utilities'
import { notify } from '../../../../services/Toastify/Toastify'
import DataTable from '../../../../services/Table/DataTable'
import { ExportToExcelTable } from '../../../../services/Excel/ExportToExcel'

import StudyModel from '../../../../models/Opperet/StudyModel'
import { opperetPaths } from '../../../routing/opperetRoutes'
import StudyModal from './StudyModal'
import { Modal } from 'bootstrap';
import { useDispatch } from 'react-redux'
import { setCurrentStudy } from '../../OpperetReducer'


type Props = {
  className: string
}

const StudyList: React.FC<Props> = ({ className }) => {
  const history = useHistory()
  const dispatch = useDispatch() // Déplacer useDispatch au niveau du composant
  const [tableReload, setTableReload] = useState(true)
  const [reloadModal, setReloadModal] = useState(false)
  const [studyId, setStudyId] = useState<number | null>(null)
  const modalRef = useRef<HTMLDivElement | null>(null)
  const [modalInstance, setModalInstance] = useState<Modal | null>(null)

  const modalActions = {
    open: (id: number | null) => {
      
      setTimeout(() => {
        setReloadModal((prev) => !prev)
       // Puis définir le nouveau ID
        setStudyId(id)
        const element = modalRef.current
        if (element) {
          const modal = new Modal(element, {
            backdrop: 'static',
            keyboard: false
          })
          setModalInstance(modal)
          modal.show()
        }
      }, 0)
    },

    close: () => {
      const element = modalRef.current
      if (element && modalInstance) {
        const handleHidden = () => {
          setStudyId(null)
          modalInstance.dispose()
          setModalInstance(null)
          element.removeEventListener('hidden.bs.modal', handleHidden)
        }
        element.addEventListener('hidden.bs.modal', handleHidden)
        modalInstance.hide()
      }
    }
  }

  const ActionEvents = {
    openEditTableDialog: (id: any) => {
      SweetAlert('warning', '', 'Voulez-vous vraiment modifier cette étude?', () => modalActions.open(id))
    },
    openDeleteTableDialog: (id: bigint) => {
      let response = responseApi
      const deleteStudy = async () => {
        const study = new StudyModel()
        const { data } = await study.delete(id)
        response = data
        if (response.type !== null && response.message !== null) {
          notify(response.type, response.message)
          if (response.type === 'success') {
            setTableReload((prev) => !prev)
          }
        }
      }
      SweetAlert('warning', '', 'Voulez-vous vraiment supprimer cette étude?', deleteStudy)
    },
    openDetail: async (id: any) => {
      const study = new StudyModel()
      const { data } = await study.find(id)
      await dispatch(setCurrentStudy(data?.result))
      history.push(opperetPaths.criterions.list.path)
    },
  }

  const columns = [
    {
      dataField: 'designation',
      text: 'Désignation',
      sort: true,
      search: {},
    },
    
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      search: {
        type: 'date',
      },
      width: 100,
    },
    {
      dataField: 'materiel_famille.name',
      text: 'Famille de matériel',
      sort: true,
      search: {},
     
      formatter: (cellContent: any, row: any) => {
        return <>{row?.materiel_famille_name}</>
      },
    },
    {
      dataField: 'family_age',
      text: 'Age de la famille',
      sort: true,
      width: 100,
      search: {},
      formatter: (cellContent: any, row: any) => {
        return <>{row?.family_age + " " + dateUnity(row?.unity_family_age)}</>
      },
    },
    {
      dataField: 'action',
      text: 'Actions',
      with: 100,
      classes: 'text-right pr-0',
      formatter: columnFormatters.ActionsColumnFormatterParams,
            formatExtraData: [
              {
                title: "Détails",
                className: "btn btn-icon btn-light-info btn-hover-info btn-sm me-1",
                svg: {
                  path: "/media/icons/duotone/General/Settings-1.svg",
                  className: "svg-icon-info"
                },
                hasAccess: CheckAccess("opperet-access"),
                onClick: ActionEvents.openDetail // Simplifier l'appel
              },
              {
                title: "Modifier",
                className: "btn btn-icon btn-light-primary btn-hover-primary btn-sm me-1",
                svg: {
                  path: "/media/icons/duotone/Communication/Write.svg",
                  className: "svg-icon-primary"
                },
                hasAccess: CheckAccess("opperet-access"),
                onClick: ActionEvents.openEditTableDialog
              },
              {
                title: "Supprimer",
                className: "btn btn-icon btn-light-danger btn-hover-danger btn-sm",
                svg: {
                  path: "/media/icons/duotone/General/Trash.svg",
                  className: "svg-icon-danger"
                },
                hasAccess: CheckAccess("opperet-access"),
                onClick: ActionEvents.openDeleteTableDialog
              },
              
            ],
      headerClasses: 'text-right pr-3',
      headerStyle: {
        minWidth: '100px',
      },
    },
  ]

  const filename = '' + currentTime()
  const exportToExcel = () => {
    (async () => {
      await ExportToExcelTable('etudes_' + filename, StudyModel, '', columns, { per_page: 'all' })
    })()
  }

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Welcome section */}
        <div className='card-body pb-0'>
          <div className='alert alert-primary d-flex align-items-center p-5 mb-10'>
            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-primary'>Bienvenue dans le module Opperet</h4>  
              <p className='mb-2'>
                Ce module est une mise en application de la méthode <span className='text-primary fw-bolder'>OPPERET</span> décrite dans 
                <span className='fw-bolder'> le FD X07-014 de l'AFNOR</span> et aussi dans 
                <span className='fw-bolder'> le guide technique du CFM : la méthode OPPERET.</span> 
                <span className='fw-bolder'> Cette méthode permet d'estimer le meilleur moment pour réétalonner les instruments de mesure.</span>
              </p>
              <ul className='list-checked list-checked-primary mb-0'>
                <li>Consulter les études précédentes réalisées par famille dans la liste ci dessous</li>
                <li>Créer une nouvelle étude en cliquant sur le bouton "Ajouter une étude"</li>
              </ul>
            </div>
          </div>
        </div>
        {/* end::Welcome section */}

        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-2 mb-1'>{opperetPaths.studies.list.title}</span>
          </h3>
          <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
            {CheckAccess('opperet-access') && (
              <a
                href={void 0}
                onClick={() => modalActions.open(null)}
                className='btn btn-sm btn-light-primary'
                title={opperetPaths.studies.add.title}
              >
                <KTSVG path='/media/icons/duotone/Interface/Plus-Square.svg' className='svg-icon-3' />
                {opperetPaths.studies.add.title}
              </a>
            )}
            
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <DataTable tableReload={tableReload} sortParams={null} dataModel={new StudyModel()} columns={columns} />
          </div>
          {/* end::Table container */}
        </div>
        {/* end::Body */}
      </div>
      <StudyModal
        studyId={studyId}
        modalActions={modalActions}
        setTableReload={setTableReload}
        modalRef={modalRef}
        reloadModal={reloadModal}
      />
    </>
  )
}

export default StudyList




