/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Link, useHistory } from "react-router-dom";
import * as columnFormatters from "../../../../services/Table/column-formatters";
import { SweetAlert } from "../../../../services/Sweetalert2/SweetAlert";
import { CheckAccess, responseApi, currentTime } from '../../../Utilities';
import { notify } from '../../../../services/Toastify/Toastify';
import DataTable from "../../../../services/Table/DataTable";
import { ExportToExcelTable } from "../../../../services/Excel/ExportToExcel";

import CriterionModel from '../../../../models/Opperet/CriterionModel';
import { opperetPaths } from '../../../routing/opperetRoutes';

type Props = {
  className: string
}
const model = new CriterionModel()
const CriterionList: React.FC<Props> = ({ className }) => {
  const history = useHistory();
  const [tableReload, setTableReload] = useState(true);

  const ActionEvents = {
    openEditTableDialog: (id: any) => {
      const openCriteriaFormToEdit = () => {
        history.push(opperetPaths.criterions.edit.path.replace(':id', id))
      }
      SweetAlert('warning', '', 'Voulez-vous vraiment modifier ce critère?', openCriteriaFormToEdit)
    },
    openDeleteTableDialog: (id: bigint) => {
      let response = responseApi;
      const deleteCriteria = async () => {
       
        const { data } = await model.delete(id)
        response = data;
        if (response.type !== null && response.message !== null) {
          notify(response.type, response.message)
          if (response.type === "success") {
            setTableReload(false)
            setTableReload(true)
          }
        }
      }
      SweetAlert('warning', '', 'Voulez-vous vraiment supprimer ce critère?', deleteCriteria)
    }
  }

  const columns = [
    {
      dataField: "materiel_famille.name",
      text: "Famille de matériel",
      sort: true,
      search: {},
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <>{row?.materiel_famille_name}</>
      },
    },
    {
      dataField: "name",
      text: "Désignation",
      sort: true,
      search: {},
    },
    {
      dataField: "weight",
      text: "Pondération",
      sort: true,
      search: {},
    },
   {
      dataField: "weightings.name",
      text: "Plots",
      
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <>{row?.weightings.map((cs: any) => cs.name + " (" + cs.value + ")").join(", ")}</>
      },
    },
    
    {
      dataField: "action",
      text: "Actions",
      classes: "text-right pr-0",
      style: {},
      formatter: columnFormatters.ActionsColumnFormatterParams,
      formatExtraData: [
        {
          title: "Modifier",
          className: "btn btn-icon btn-light-primary btn-hover-primary btn-sm me-1",
          svg: {
            path: "/media/icons/duotone/Communication/Write.svg",
            className: "svg-icon-primary"
          },
          hasAccess: CheckAccess("opperet-access"),
          onClick: ActionEvents.openEditTableDialog
        },
        {
          title: "Supprimer",
          className: "btn btn-icon btn-light-danger btn-hover-danger btn-sm",
          svg: {
            path: "/media/icons/duotone/General/Trash.svg",
            className: "svg-icon-danger"
          },
          hasAccess: CheckAccess("opperet-access"),
          onClick: ActionEvents.openDeleteTableDialog
        },
      ],
      headerClasses: "text-right pr-3",
      headerStyle: {
        width: "15%"
      },
    },
  ];

  const filename = "" + currentTime();
  const exportToExcel = () => {
    (async () => {
      await ExportToExcelTable(
        "criteres_" + filename,
        CriterionModel,
        "criterions",
        columns,
        { per_page: "all" }
      );
    })()
  }

  return (
    <div className={`card mb-5 mb-xl-8 ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-2 mb-1'>
            {""}
          </span>
        </h3>
        <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
          {CheckAccess("criteria-add") && (
            <Link
              to={opperetPaths.criterions.add.path}
              className='btn btn-sm btn-light-primary'
              title={opperetPaths.criterions.add.title}
            >
              <KTSVG path='/media/icons/duotone/Interface/Plus-Square.svg' className='svg-icon-3' />
              {opperetPaths.criterions.add.title}
            </Link>
          )}
          
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <DataTable
            tableReload={tableReload}
            sortParams={null}
            dataModel={new CriterionModel()}
            columns={columns}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default CriterionList 