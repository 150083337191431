export interface Timestamps {
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
}

export interface MaterielFamille extends Timestamps {
  id?: number;
  name: string;
}

export interface GmoCriterion extends Timestamps {
  id?: number;
  name: string;
  weight?: number; // Peut être NULL, donc optionnel
  description?: string;
  materiel_famille_id: number | null;
  gmo_study_id: number | null;
  family?: MaterielFamille;
  weightings?: GmoWeighting[];
}

export interface GmoWeighting extends Timestamps {
  id?: number;
  name: string;
  value?: number;
  gmo_criterion_id: number;
  gmo_study_id: number | null;
  criterion?: GmoCriterion;
}

export interface GmoStudy extends Timestamps {
  id?: number;
  designation: string;
  description?: string;
  family_age: number;
  unity_family_age: string;
  date: string;
  materiel_famille_id: number | undefined;
  family?: MaterielFamille;
}

// État pour le module Opperet
export interface OpperetState {
  currentStudy: GmoStudy | null;
  isStudyLoading: boolean;
  error: string | null;
}

export const plots = [
  { ref: 3, plot: '-1,0,1' },
  
  { ref: 4, plot: '-2,-1,1,2' },
  { ref: 5, plot: '-2,-1,0,1,2' }
]
