import * as Excel from 'exceljs'
import DashBoardModel from '../../models/gp/DashBoardModel'
import {saveFile} from '../../app/Utilities'

export const ExportToExcelRaccordementNearOrOutDate = async (params: any = {}) => {
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet('My Sheet')
  worksheet.columns = [
    {header: 'Désignation', key: 'materiel_design_mat', width: 10},
    {header: 'Code', key: 'materiel_code_mat', width: 32},
    {header: 'Date prochaine de rac', key: 'date_rac_next', width: 15},
    {header: 'N° de série', key: 'materiel_serie_mat', width: 15},
    {header: 'Type/Modele', key: 'materiel_type_model_mat', width: 15},
    {header: 'Constructeur', key: 'materiel_constructeur_mat', width: 15},
    {header: 'Affectation', key: 'materiel_affectation_mat', width: 15},
    {header: 'Domaine', key: 'domaine_design_dom', width: 15},
    {header: "Point d'étalonnage", key: 'prog_calibration_point', width: 15},
    {header: 'Incertitude souhaitée', key: 'prog_desired_uncertainty', width: 15},
    {header: 'EMT', key: 'prog_emt', width: 15},
  ]

  let dataModel = new DashBoardModel()
  const {data} = await dataModel.getComponentExportToExcelRaccordementNearOutDate(params)

  if (data.type === 'success') {
    let rows = data.result
    rows.length > 0 &&
      rows.map((row: any) => {
        worksheet.addRow({
          materiel_design_mat: row.materiel_design_mat,
          materiel_code_mat: row.materiel_code_mat,
          date_rac_next: row.date_rac_next,
          materiel_serie_mat: row.materiel_serie_mat,
          materiel_type_model_mat: row.materiel_type_model_mat,
          materiel_constructeur_mat: row.materiel_constructeur_mat,
          materiel_affectation_mat: row.materiel_affectation_mat,
          domaine_design_dom: row.domaine_design_dom,
          prog_calibration_point: row.prog_calibration_point,
          prog_desired_uncertainty: row.prog_desired_uncertainty,
          prog_emt: row.prog_emt,
        })
      })
  }

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const fileExtension = '.xlsx'
  const blob = new Blob([buffer], {type: fileType})
  saveFile(blob, 'raccordement-near-or-out-date' + fileExtension)
}

export const ExportToExcelTable = async (
  filename: string,
  dataModel: any,
  path: any,
  columns: any,
  params: any
) => {
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet('My Sheet')

  let headerColumns: any = []

  columns.length > 0 &&
    columns.map((column: any) => {
      headerColumns.push({
        header: column.text,
        key: column.dataField.split('.').join('_'),
        width: column?.width || 10,
      })
    })

  worksheet.columns = headerColumns

  try {
    const {data} = await dataModel.getWithParams(path, params)
    if (data.type === 'success') {
      let rows = Object.values(data.result.data)
      rows.length > 0 &&
        rows.map((row: any) => {
          let addRow: any = {}
          columns.length > 0 &&
            columns.map((column: any) => {
              let dataField = column['dataField'].split('.').join('_')
              addRow[dataField] = row[dataField]
            })
          //console.log(addRow)
          worksheet.addRow(addRow)
        })
    }

    /**/ const buffer = await workbook.xlsx.writeBuffer()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const fileExtension = '.xlsx'
    const blob = new Blob([buffer], {type: fileType})
    saveFile(blob, filename + fileExtension)
  } catch (e) {
    console.log('error')
  }
}
