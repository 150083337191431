import { lazy } from 'react'
import { Redirect, Route, Switch } from "react-router-dom"

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import CriterionList from './CriterionList'
import CriterionForm from './CriterionForm'
import { opperetPaths } from '../../../routing/opperetRoutes'




const CriterionPage = () => {
    const breadCrumbs: Array<PageLink> = [
        
    ]
    return (
        <Switch>
            <Route path={opperetPaths.criterions.list.path} exact>
                <PageTitle defaultTitle={''} breadcrumbs={breadCrumbs}>
                    {opperetPaths.criterions.list.title}
                </PageTitle>
                <CriterionList  className='mb-5 mb-xl-8' />
            </Route>
            <Route path={opperetPaths.criterions.add.path} exact>
                <PageTitle defaultTitle={""} breadcrumbs={breadCrumbs}>
                    {opperetPaths.criterions.add.title}
                </PageTitle>
                <CriterionForm className='mb-5 mb-xl-8' />
            </Route>
            <Route path={opperetPaths.criterions.edit.path} exact >
                <PageTitle defaultTitle={""} breadcrumbs={breadCrumbs}>
                    {opperetPaths.criterions.edit.title}
                </PageTitle>
                <CriterionForm className='mb-5 mb-xl-8' />
            </Route>
            {/* Autres routes du module Opperet */}
            <Redirect to='/error/404' />
        </Switch>
    )
}

export default CriterionPage

