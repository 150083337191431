/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import DashBoardAllDepartmentModel from "../../../../../../models/gp/DashBoardAllDepartmentModel";

type TypeStatusAllDepartments = {
    [label: string]: any
}

const statusResult = {
    nbr: 0,
    color: '',
    status: ''
}

const CountQualificationOutOrUpDateAllDep: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [statusAllDepartments, setStatusAllDepartments] = useState<Array<TypeStatusAllDepartments>>([])

    const getStatusAllDepartments = async () => {
        let dataModel = new DashBoardAllDepartmentModel()
        const {data} = await dataModel.getComponentCountQualificationOutOrUpDate()
        if (data.type === "success") {
            return data.result
        } else {
            return []
        }
    }

    useEffect(() => {
        let isCancelled = false;
        getStatusAllDepartments().then((response: any) => {
            if (!isCancelled) {
                setStatusAllDepartments(prevState => response)
            }
        })
        return () => {
            isCancelled = true
        };
    }, [])

    return (
        <>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-2 mb-1'>
                    Etat de qualifications du personnel
                </span>
                <span className='text-muted fw-bold fs-2'></span>
            </h3>
            
            <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                    <thead>
                        <tr className='border'>
                            <th className='text-muted w-400px'>Département</th>
                            <th className='text-muted text-center'>Nombre de qualifications</th>
                            <th className='text-muted text-center'>Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statusAllDepartments.length > 0 ? (
                            statusAllDepartments.map((statusAllDepartment, statusAllDepartmentIndex) => {
                                if (statusAllDepartment?.result?.not_update > 0) {
                                    statusResult.nbr = statusAllDepartment?.result?.not_update
                                    statusResult.color = 'danger'
                                    statusResult.status = 'En retard'
                                } else if (statusAllDepartment?.result?.near_out_date > 0) {
                                    statusResult.nbr = statusAllDepartment?.result?.near_out_date
                                    statusResult.color = 'orange'
                                    statusResult.status = 'Proche'
                                } else {
                                    statusResult.nbr = statusAllDepartment?.result?.update
                                    statusResult.color = 'success'
                                    statusResult.status = 'Rien à signaler'
                                }

                                return (
                                    <tr key={statusAllDepartmentIndex + "_statusAllDepartment"}>
                                        <td>
                                            <a href={void(0)} className='fs-6 text-dark text-hover-primary text-uppercase'>
                                                {statusAllDepartment?.design_dep}
                                            </a>
                                            <span className='text-muted d-block'>
                                                {statusAllDepartment?.result?.persons} personnes
                                            </span>
                                        </td>
                                        <td className='text-center'>
                                            <span className={'fs-6 text-' + statusResult.color}>
                                                {statusResult.nbr}
                                            </span>
                                        </td>
                                        <td className='text-center'>
                                            <span className={'badge badge-light-' + statusResult.color + ' text-uppercase'}>
                                                {statusResult.status}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td colSpan={3} className='text-center'>
                                    <span className='fw-bolder text-muted'>
                                        Pas d'informations ...
                                    </span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export {CountQualificationOutOrUpDateAllDep}

