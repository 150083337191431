import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'

import {CheckAccess} from '../Utilities';

import {GeneralConditionPage} from '../pages/general_condition/GeneralConditionPage';
import {DataProtectionPage} from '../pages/data_protections/DataProtectionPage';

import {DashboardInstrument} from '../instrument_park/dashboard/DashboardInstrument'

export function PrivateRoutes({currentApp}: any) {
    // Parc instruments
    const AccountPage = lazy(() => import('../accounts/AccountPage'))
    const DepartmentsPage = lazy(() => import('../instrument_park/departments/DepartmentsPage'))
    const MaterialsPage = lazy(() => import('../instrument_park/materials/MaterialsPage'))
    const ProgRaccordementsPage = lazy(() => import('../instrument_park/programmes/raccordements/ProgRaccordementsPage'))
    const ProgVerificationsPage = lazy(() => import('../instrument_park/programmes/verifications/ProgVerificationsPage'))
    const RaccordementsPage = lazy(() => import('../instrument_park/raccordements/RaccordementsPage'))
    const VerificationsPage = lazy(() => import('../instrument_park/verifications/VerificationsPage'))
    const QualificationsPage = lazy(() => import('../instrument_park/qualifications/QualificationsPage'))
    const PersonsPage = lazy(() => import('../instrument_park/persons/PersonsPage'))
    const ServicesProvidersPage = lazy(() => import('../instrument_park/services_providers/ServicesProvidersPage'))
    const SettingsPage = lazy(() => import('../instrument_park/settings/SettingsPage'))
    const OpperetRoutes = lazy(() => import('./opperetRoutes'))

    const RenderRoute = ({app}: any) => {
        console.log(currentApp + " -----")
        switch (app) {
            case 'GP':
                return (
                    <Switch>
                        <Route path='/my-account' component={AccountPage}/>
                        <Route path='/instrument/dashboard' component={DashboardInstrument} exact={true}/>
                        {
                            CheckAccess("department-access") &&
                            <Route path='/instrument/departments' component={DepartmentsPage}/>
                        }
                        {
                            CheckAccess("material-access") &&
                            <Route path='/instrument/materials' component={MaterialsPage}/>
                        }
                        {
                            CheckAccess("program-etalonnage-access") &&
                            <Route path='/instrument/programmes/raccordements' component={ProgRaccordementsPage}/>
                        }
                        {
                            CheckAccess("program-verification-access", "suivi_verif") &&
                            <Route path='/instrument/programmes/verifications' component={ProgVerificationsPage}/>
                        }
                        {
                            CheckAccess("raccordement-access") &&
                            <Route path='/instrument/raccordements' component={RaccordementsPage}/>
                        }
                        {
                            CheckAccess("verification-access", "suivi_verif") &&
                            <Route path='/instrument/verifications' component={VerificationsPage}/>
                        }
                        {
                            CheckAccess("qualification-access", "suivi_qualif") &&
                            <Route path='/instrument/qualifications' component={QualificationsPage}/>
                        }
                        {
                            CheckAccess("person-access") &&
                            <Route path='/instrument/persons' component={PersonsPage}/>
                        }
                        {
                            CheckAccess("service-provider-access", "service_provider") &&
                            <Route path='/instrument/services-providers' component={ServicesProvidersPage}/>
                        }
                        {
                            CheckAccess("setting-access") &&
                            <Route path='/instrument/settings' component={SettingsPage}/>
                        }
                        
                        {/* Ajout de la route Opperet */}
                        {
                            //CheckAccess("opperet-access") &&
                            <Route path='/opperet/*' render={(props) => <OpperetRoutes  />} />
                        }
                        
                        <Route path='/general-condition' component={GeneralConditionPage}/>
                        <Route path='/data-protection' component={DataProtectionPage}/>
                        
                        <Redirect from="/auth" to='/instrument/dashboard'/>
                        <Redirect from="/" exact to='/instrument/dashboard'/>
                    </Switch>
                );
            
            default:
                return <Switch><Redirect to='/'/></Switch>
        }
    }

    return (
        <Suspense fallback={<FallbackView/>}>
            <RenderRoute app={currentApp}/>
        </Suspense>
    )
}

