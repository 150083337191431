import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class FamilyModel extends Model {
    private prefix = "opperet/families"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getAll(params: any) {
        let path = this.prefix
        return this.withParams(path, params)
    }

    getCriterionByFamily(id: number, params: any) {
        return this.withParams(`${this.prefix}/${id}/criterions`, params)
    }

    getInstrumentsByFamily(id: number, params: any) {
        return this.withParams(`${this.prefix}/${id}/instruments`, params)
    }

    getRatingsByFamily(id: number, params: any) {
        return this.withParams(`${this.prefix}/${id}/ratings`, params)
    }


    add(data: {}) {
        return this.post(this.prefix + `/save-add`, data)
    }

    find(id: bigint) {
        return this.get(this.prefix + `/find/${id}`)
    }

    update(id: bigint, data: {}) {
        return this.post(this.prefix + `/save-edit/${id}`, data)
    }

    delete(id: bigint) {
        return this.get(this.prefix + `/delete/${id}`)
    }

    ageMoyenParc(id : any){

        return this.withParams(`${this.prefix}/${id}/age-moyen-parc`,{disabled_cdi_header:1})
    }

}

export default FamilyModel;
