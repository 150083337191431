let _this = new Date()

export const dateMysql = function () {
  return (
    _this.getFullYear() +
    '-' +
    String(_this.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(_this.getDate()).padStart(2, '0')
  )
}

export const dateFromMysqlDate = function (date) {
  if (!date) return null
  let _array = date.split('-')
  return _array[2] + '/' + _array[1] + '/' + _array[0]
  //_that.getFullYear()+'-'+String(_that.getMonth()+1).padStart(2, '0')+'-'+String(_that.getDate()).padStart(2, '0')
}

export const dateFromMysqlDateTime = function (datetime) {
  if (!datetime) return null
  let _array_date_time = datetime.split(' ')
  let _array = _array_date_time[0].split('-')
  return _array[2] + '/' + _array[1] + '/' + _array[0]
  //_that.getFullYear()+'-'+String(_that.getMonth()+1).padStart(2, '0')+'-'+String(_that.getDate()).padStart(2, '0')
}

export const time = function () {
  return (
    String(_this.getHours()).padStart(2, '0') +
    ':' +
    String(_this.getMinutes()).padStart(2, '0') +
    ':' +
    String(_this.getSeconds()).padStart(2, '0')
  )
}

export const dateTime = function () {
  return (
    _this.getFullYear() +
    '-' +
    String(_this.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(_this.getDate()).padStart(2, '0') +
    ' ' +
    String(_this.getHours()).padStart(2, '0') +
    ':' +
    String(_this.getMinutes()).padStart(2, '0') +
    ':' +
    String(_this.getSeconds()).padStart(2, '0')
  )
}

export const addTime = function (t) {
  let time = t.split(':')
  let rd = new Date(_this.setHours(_this.getHours() + parseInt(time[0])))
  rd = new Date(rd.setMinutes(rd.getMinutes() + parseInt(time[1])))
  return new Date(rd.setSeconds(rd.getSeconds() + parseInt(time[2])))
}

export const addDate = function (t) {
  let time = t.split('-')
  let rd = new Date(_this.setFullYear(_this.getFullYear() + parseInt(time[0])))
  rd = new Date(rd.setMonth(rd.getMonth() + parseInt(time[1])))
  return new Date(rd.setDate(rd.getDate() + parseInt(time[2])))
}

export const subDate = function (t) {
  let time = t.split('-')
  let rd = new Date(_this.setFullYear(_this.getFullYear() - parseInt(time[0])))
  rd = new Date(rd.setMonth(rd.getMonth() - parseInt(time[1])))
  return new Date(rd.setDate(rd.getDate() - parseInt(time[2])))
}

/**
 * Other fonction
 */
export const getStartAndEndDates = () => {
  // Fonction utilitaire pour formater une date en "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ajout d'un zéro si nécessaire
    const day = date.getDate().toString().padStart(2, '0') // Ajout d'un zéro si nécessaire
    return `${year}-${month}-${day}`
  }

  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth() // Mois courant (0-11)

  // Date actuelle
  const currentDate = formatDate(now)

  // Début et fin du mois courant
  const startOfMonth = formatDate(new Date(year, month, 1)) // 1er jour du mois
  const endOfMonth = formatDate(new Date(year, month + 1, 0)) // Dernier jour du mois

  // Début et fin de l'année courante
  const startOfYear = formatDate(new Date(year, 0, 1)) // 1er janvier
  const endOfYear = formatDate(new Date(year, 11, 31)) // 31 décembre

  return {
    currentDate,
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
  }
}
