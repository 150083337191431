import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { KTSVG } from '../../../../_metronic/helpers'
import FamilyModel from '../../../../models/Opperet/FamilyModel'
import StudyModel from '../../../../models/Opperet/StudyModel'
import { consoleLog, responseApi, dateUnities } from '../../../Utilities'
import { notify } from '../../../../services/Toastify/Toastify'
import { opperetPaths } from '../../../routing/opperetRoutes'

type Props = {
  studyId: any
  modalActions: {
    open: (id: any) => void
    close: () => void
  }
  setTableReload: any
  modalRef: React.RefObject<HTMLDivElement>,
  reloadModal: any
}

const StudyModal: React.FC<Props> = ({ studyId, modalActions, setTableReload, modalRef, reloadModal }) => {
  const [loading, setLoading] = useState(false)
  const isAddMode = !studyId
  const formTitle = isAddMode ? opperetPaths.studies.add.title : opperetPaths.studies.edit.title

  const initialStudy = {
    designation: '',
    description: '',
    family_age: '',
    unity_family_age: dateUnities.filter((unity) => unity?.default == true)[0]?.value,
    date: '',
    materiel_famille_id: '',
  }

  const [Study, setStudy] = useState(initialStudy)
  const [families, setFamilies] = useState([])

  const findStudy = async (id: bigint) => {
    let dataModel = new StudyModel()
    const { data } = await dataModel.find(id)
    if (data.type === "success") {
      setStudy({
        designation: data.result?.designation || '',
        description: data.result?.description || '',
        family_age: data.result?.family_age || null,
        unity_family_age: data.result?.unity_family_age || '',
        date: data.result?.date || '',
        materiel_famille_id: data.result?.materiel_famille_id || '',
      })
    } else {
      setStudy(initialStudy)
    }
  }

  // Effet pour charger les données initiales
  useEffect(() => {
    const loadData = async () => {
      if (studyId) {
        await findStudy(studyId)
      } else {
        formik.resetForm()
        setStudy(initialStudy)
      }
      consoleLog('Study data loaded:', studyId)
    }
    loadData()
  }, [studyId,reloadModal]) // Ajouter reloadModal comme dépendance

  // Effet séparé pour charger les familles
  useEffect(() => {
    const loadFamilies = async () => {
      const familyModel = new FamilyModel()
      const { data } = await familyModel.getAll({ per_page: 'all' })
      if (data.type === "success") {
        setFamilies(data.result?.data || [])
      }
    }
    loadFamilies()
  }, [])

  const StudySchema = Yup.object().shape({
    designation: Yup.string().typeError('Veuillez saisir une désignation !').required('Ce champ est requis !'),
    description: Yup.string().nullable(),
    family_age: Yup.number().typeError('Veuillez saisir un nombre !').required('Ce champ est requis !'),
    unity_family_age: Yup.string().typeError('Veuillez sélectionner une unité !').required('Ce champ est requis !'),
    date: Yup.string().typeError('Veuillez sélectionner une date !').required('Ce champ est requis !'),
    materiel_famille_id: Yup.string().typeError('Veuillez sélectionner une famille !').required('Ce champ est requis !'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Study,
    validationSchema: StudySchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setTableReload(false)
      setLoading(true)
      try {
        let response = responseApi
        const study = new StudyModel()
        if (!isAddMode) {
          const { data } = await study.update(studyId, values)
          response = data
        } else {
          const { data } = await study.add(values)
          response = data
        }
        
        if (response.type !== null && response.message !== null) {
          notify(response.type, response.message)
          if (response.type === 'success') {
            if (!studyId) resetForm()
            setTableReload(true)
          }
        } else {
          notify('error', 'Opération non effectuée | catch error')
        }
      } catch (error) {
        notify('error', 'Une erreur est survenue')
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleFamilyChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    // Convertir en string pour le formik
    formik.setFieldValue('materiel_famille_id', value);

    if (value) {
      try {
        const familyModel = new FamilyModel()
        const { data } = await familyModel.ageMoyenParc((value)) // Convertir en number pour l'API
        
        if (data.type === "success") {
          formik.setFieldValue('family_age', data.result.age_moyen_parc)
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'âge moyen du parc:', error)
      }
    }
  }

  return (
    <div 
      className='modal fade' 
      id='study_modal' 
      tabIndex={-1} 
      aria-hidden='true' 
      ref={modalRef}
    >
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          {/* begin::Modal header */}
          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>{formTitle}</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div 
              className='btn btn-icon btn-sm btn-active-icon-primary' 
              onClick={modalActions.close}
              data-kt-studies-modal-action='close'
            >
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          {/* end::Modal header */}

          {/* begin::Form */}
          <form 
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {/* begin::Modal body */}
            <div className='modal-body py-10 px-lg-17'>
              {/* begin::Scroll */}
              <div 
               /* className='scroll-y me-n7 pe-7'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_study_header'
                data-kt-scroll-wrappers='#kt_modal_add_study_scroll'
                data-kt-scroll-offset='300px'*/
              >
                {/* begin::Input group - Designation */}
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Désignation</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('designation')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.designation && formik.errors.designation},
                      {'is-valid': formik.touched.designation && !formik.errors.designation}
                    )}
                    placeholder='Saisir la désignation'
                  />
                  {formik.touched.designation && formik.errors.designation && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.designation}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Input group */}

                 {/* begin::Input group - Date */}
                 <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Date</label>
                  <input
                    type='date'
                    {...formik.getFieldProps('date')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.date && formik.errors.date},
                      {'is-valid': formik.touched.date && !formik.errors.date}
                    )}
                  />
                  {formik.touched.date && formik.errors.date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.date}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Input group */}

               

                {/* begin::Input group - Material Family */}
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Famille de matériel</label>
                  <select
                    value={formik.values.materiel_famille_id || ''} // Ajouter une valeur par défaut vide
                    className={clsx(
                      'form-select form-select-solid fw-bolder',
                      {'is-invalid': formik.touched.materiel_famille_id && formik.errors.materiel_famille_id},
                      {'is-valid': formik.touched.materiel_famille_id && !formik.errors.materiel_famille_id}
                    )}
                    onChange={handleFamilyChange}
                  >
                    <option value=''>Sélectionner une famille</option>
                    {families.map((famille: any) => (
                      <option key={famille.id} value={famille.id.toString()}> {/* Convertir l'id en string */}
                        {famille.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.materiel_famille_id && formik.errors.materiel_famille_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.materiel_famille_id}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Input group */}

                {/* begin::Input group - Family Age with Unity */}
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Age de la famille</label>
                  <div className='input-group input-group-solid'>
                    <input
                      type='number'
                      {...formik.getFieldProps('family_age')}
                      className={clsx(
                        'form-control w-75',
                        {'is-invalid': formik.touched.family_age && formik.errors.family_age},
                        {'is-valid': formik.touched.family_age && !formik.errors.family_age}
                      )}
                      placeholder='Saisir lâge de la famille'
                    />
                    
                  </div>
                  {(formik.touched.family_age && formik.errors.family_age) || 
                   (formik.touched.unity_family_age && formik.errors.unity_family_age) ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>
                          {formik.errors.family_age || formik.errors.unity_family_age}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* end::Input group */}

               
                {/* begin::Input group - Description */}
                <div className='fv-row mb-7'>
                  <label className='fw-bold fs-6 mb-2'>Description</label>
                  <textarea
                    {...formik.getFieldProps('description')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.description && formik.errors.description},
                      {'is-valid': formik.touched.description && !formik.errors.description}
                    )}
                    rows={4}
                    placeholder='Saisir la description'
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Input group */}
                
              </div>
              {/* end::Scroll */}
            </div>
            {/* end::Modal body */}

            {/* begin::Modal footer */}
            <div className='modal-footer flex-center'>
              {/* begin::Button */}
              <button
                type='button'
                className='btn btn-light me-3'
                onClick={modalActions.close}
              >
                Annuler
              </button>
              {/* end::Button */}

              {/* begin::Button */}
              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Enregistrer</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Patientez...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* end::Button */}
            </div>
            {/* end::Modal footer */}
          </form>
          {/* end::Form */}
        </div>
      </div>
    </div>
  )
}

export default StudyModal


