import { Redirect, Route, Switch } from "react-router-dom";

import CriterionPage from "./pages/criterion/CriterionPage"
import RatingPage from "./pages/rating/RatingPage"
import OpperetHeader from "./components/OpperetHeader";
import { opperetPaths, pathOpperetModule } from "../routing/opperetRoutes";
import StudyPage from "./pages/study/StudyPage";
type Props = {
    mode?: string
}
const OpperetPage: React.FC<Props> = ({ mode = ''}) => {

  if(mode != 'tab') {
    return (
      <>
        <Switch>
          <Route path={opperetPaths.studies.root + '/*'} render={(props) => <StudyPage  />} exact/>
        </Switch>
      </>
    )
  }else
  return (
    <>
      <OpperetHeader/>
      <Switch>
        <Route path={opperetPaths.criterions.root + '/*'} render={(props) => <CriterionPage  />} exact/>
        <Route path={opperetPaths.ratings.root + '/*'} render={(props) => <RatingPage   />} exact/>
        <Redirect to='/error/404' />
      </Switch>
    </>
  )
}

export default OpperetPage