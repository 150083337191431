import Model from '../Model'

class DashBoardModel extends Model {
  private prefix = 'instruments/dashboard'

  getWithParams(path: any, params: any) {
    path = this.prefix + path
    return this.withParams(path, params)
  }

  getComponentCountRaccordementOutOrUpDate() {
    let path = '/component/count/out-or-up-date/raccordement'
    return this.getWithParams(path, {})
  }

  getComponentCountVerificationOutOrUpDate() {
    let path = '/component/count/out-or-up-date/verification'
    return this.getWithParams(path, {})
  }

  getComponentCountQualificationOutOrUpDate() {
    let path = '/component/count/out-or-up-date/qualification'
    return this.getWithParams(path, {})
  }

  getComponentStatusEquipmentAvailablity() {
    let path = '/component/status/equipments/availablity'
    return this.getWithParams(path, {})
  }

  getComponentPrevisionRaccordementCost() {
    let path = '/component/prevision/raccordements/cost'
    return this.getWithParams(path, {})
  }

  getComponentlistsRaccordementNearOutDate() {
    let path = '/component/lists/raccordements/near-out-date'
    return this.getWithParams(path, {})
  }

  getComponentlistsQualificationNearOutDate() {
    let path = '/component/lists/qualifications/near-out-date'
    return this.getWithParams(path, {})
  }

  getComponentExportToExcelRaccordementNearOutDate(params: any = {}) {
    let path = '/component/export/excel/raccordements/near-out-date'
    return this.getWithParams(path, params)
  }
}

export default DashBoardModel
