//import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import AuthReducer from "../../app/auth/AuthRedux";
import AppReducer from "./AppRedux";
import OpperetReducer from '../../app/opperet/OpperetReducer';

export const rootReducer = combineReducers({
    authReducer : AuthReducer,
    appReducer : AppReducer,
    opperetReducer: OpperetReducer, // Utiliser le reducer persistant
})
