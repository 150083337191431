import * as Excel from 'exceljs';
import { saveFile, currentTime } from '../../../Utilities';

export const legendItems = {
  0: { abbr: 'INST', full: 'INSTRUMENTS' },
  1: { abbr: 'NT', full: 'NOTES' },
  2: { abbr: 'ENC', full: 'ECART NORMALISÉ CRITÈRE' },
  3: { abbr: 'NG', full: 'NOTE GLOBALE' },
  4: { abbr: 'ENG', full: 'ECART NORMALISÉ GLOBAL' },
  5: { abbr: 'FS', full: 'FACTEUR S' },
  6: { abbr: 'CO', full: 'CORRECTION OPPERET' },
  7: { abbr: 'FO', full: 'FRÉQUENCE OPTIMISÉE' }
};

export const getHiddenInputValue = (name: string, defaultValue = '0') =>
  document.querySelector<HTMLInputElement>(`input[name="${name}"]`)?.value || defaultValue;

export interface Criterion {
  id: string;
  weight: number;
  name: string;
  weightings?: Array<{ id: string; value: number }>;
}

export interface Instrument {
  id: string;
  design_mat: string;
  code_mat: string;
  calibration_frequency: number;
}

export interface Score {
  instrument_id: string;
  criterion_id: string;
  score: number;
}

export class RatingCalculator {
  private criterions: Criterion[];
  private instruments: Instrument[];
  private scores: Record<string, Record<string, number>>;
  private parkAverageAge: number;

  constructor(
    criterions: Criterion[],
    instruments: Instrument[],
    scores: Record<string, Record<string, number>>,
    parkAverageAge?: number 
  ) {
    this.criterions = criterions;
    this.instruments = instruments;
    this.scores = scores;
    this.parkAverageAge = parkAverageAge || 0;
  }

  calculateStatisticsByCriterion(criterionId: string) {
    const allScores = this.instruments
      .map(instrument => Number(this.scores[instrument.id]?.[criterionId]))
      .filter(score => !isNaN(score));

    const mean = allScores.length > 0 
      ? Number((allScores.reduce((sum, score) => sum + score, 0) / allScores.length).toFixed(2))
      : 0;

    let standardDeviation = 0;
    if (allScores.length > 1) {
      const squareDiffs = allScores.map(score => Math.pow(score - mean, 2));
      const avgSquareDiff = squareDiffs.reduce((sum, diff) => sum + diff, 0) / (allScores.length - 1);
      standardDeviation = Number(Math.sqrt(avgSquareDiff).toFixed(2));
    }

    return {
      mean,
      standardDeviation
    };
  }

  calculateENC(score: number, mean: number, standardDeviation: number): number {
    return Number((standardDeviation !== 0 ? (score - mean) / standardDeviation : 0).toFixed(2));
  }

  calculateNG(instrumentId: string): number {
    const ng = this.criterions.reduce((sum, criterion) => {
      const stats = this.calculateStatisticsByCriterion(criterion.id);
      const score = Number(this.scores[instrumentId]?.[criterion.id]) || 0;
      const enc = this.calculateENC(score, stats.mean, stats.standardDeviation);
      return sum + (criterion.weight * enc);
    }, 0);
    return Number(ng.toFixed(2));
  }

  calculateENG(instrumentId: string): number {
    const allNG = this.instruments.map(inst => this.calculateNG(inst.id));
    const meanNG = allNG.reduce((sum, ng) => sum + ng, 0) / allNG.length;
    const stdDevNG = Math.sqrt(
      allNG.reduce((sum, ng) => sum + Math.pow(ng - meanNG, 2), 0) / (allNG.length - 1)
    );

    const instrumentNG = this.calculateNG(instrumentId);
    return Number((stdDevNG !== 0 ? (instrumentNG - meanNG) / stdDevNG : 0).toFixed(2));
  }

  calculateFactorS(instrumentId: string): number {
    const instrument = this.instruments.find(i => i.id === instrumentId);
    if (!instrument) return 0;
    
    return Number(((this.parkAverageAge - instrument.calibration_frequency) / 5).toFixed(2));
  }

  calculateOpperetCorrection(instrumentId: string): number {
    const instrument = this.instruments.find(i => i.id === instrumentId);
    if (!instrument) return 0;

    const eng = this.calculateENG(instrumentId);
    const factorS = this.calculateFactorS(instrumentId);
    const calibrationFrequency = instrument.calibration_frequency;

    return Math.round(
      (calibrationFrequency + (2 * factorS) + (eng * factorS)) - calibrationFrequency
    );
  }

  calculateOptimizedFrequency(instrumentId: string): number {
    const instrument = this.instruments.find(i => i.id === instrumentId);
    if (!instrument) return 0;

    const correction = this.calculateOpperetCorrection(instrumentId);
    return Number((instrument.calibration_frequency + correction).toFixed(2));
  }

  getInstrumentCalculations(instrumentId: string) {
    const ng = this.calculateNG(instrumentId);
    const eng = this.calculateENG(instrumentId);
    const factorS = this.calculateFactorS(instrumentId);
    const opperet = this.calculateOpperetCorrection(instrumentId);
    const optimizedFrequency = this.calculateOptimizedFrequency(instrumentId);

    return {
      ng,
      eng,
      factorS,
      opperet,
      optimizedFrequency
    };
  }

  getCriterionCalculations(instrumentId: string, criterionId: string) {
    const stats = this.calculateStatisticsByCriterion(criterionId);
    const score = Number(this.scores[instrumentId]?.[criterionId]) || 0;
    const enc = this.calculateENC(score, stats.mean, stats.standardDeviation);

    return {
      score,
      enc
    };
  }

 
}



export const exportRatingToExcel = (criterions : any, instruments : any, scores : any ) => {
  if (!criterions.length || !instruments.length) return;

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Notation');

  const headerStyle = {
    font: { bold: true, color: { argb: '2C3E50' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E8F3FF' } },
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    },
    alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }
  };

  const columns = [
    { header: '', key: 'instrument', width: 30 },
    ...criterions.map(() => ({ width: 15 })),
    ...criterions.map(() => ({ width: 15 })),
    { width: 15 },
    { width: 15 },
    { width: 15 },
    { width: 15 },
    { width: 15 }
  ];
  worksheet.columns = columns;

  const headerRow1 = worksheet.addRow(['']);
  const firstRowCells = [
    { value: legendItems[0].full, colspan: 1 },
    { value: legendItems[1].full, colspan: criterions.length },
    { value: legendItems[2].full, colspan: criterions.length },
    { value: legendItems[3].full, colspan: 1 },
    { value: legendItems[4].full, colspan: 1 },
    { value: legendItems[5].full, colspan: 1 },
    { value: legendItems[6].full, colspan: 1 },
    { value: legendItems[7].full, colspan: 1 }
  ];

  let currentCol = 1;
  firstRowCells.forEach(cell => {
    if (cell.colspan > 1) {
      worksheet.mergeCells(1, currentCol, 1, currentCol + cell.colspan - 1);
    }
    headerRow1.getCell(currentCol).value = cell.value;
    currentCol += cell.colspan;
  });

  const headerRow2 = worksheet.addRow([
    '',
    ...criterions.map((c: any) => c.name),
    ...criterions.map((c : any) => c.name),
    '',
    '',
    '',
    '',
    ''
  ]);

  [headerRow1, headerRow2].forEach(row => {
    row.eachCell((cell : any) => {
      cell.style = headerStyle;
    });
  });

  instruments.forEach((instrument: any, idx : number) => {
    const rowData : any[]  = [
      instrument.code_mat ? `${instrument.design_mat} (${instrument.code_mat})` : instrument.design_mat
    ];

    criterions.forEach((criterion : any) => {
      rowData.push(scores[instrument.id]?.[criterion.id] || 0);
    });

    criterions.forEach((criterion : any) => {
      const encValue = getHiddenInputValue(
        `calculations[${instrument.id}][criterions][${criterion.id}][enc]`
      );
      rowData.push(encValue);
    });

    rowData.push(
      getHiddenInputValue(`calculations[${instrument.id}][ng]`),
      getHiddenInputValue(`calculations[${instrument.id}][eng]`),
      getHiddenInputValue(`calculations[${instrument.id}][factor_s]`),
      getHiddenInputValue(`calculations[${instrument.id}][opperet_correction]`),
      getHiddenInputValue(`calculations[${instrument.id}][optimized_frequency]`)
    );

    const row = worksheet.addRow(rowData);

    if (idx % 2 === 1) {
      row.eachCell(cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F9F9F9' }
        };
      });
    }
  });

  worksheet.eachRow(row => {
    row.eachCell(cell => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
  });

  const filename = `notation_instruments_${currentTime()}`;
  return workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { 
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    });
    saveFile(blob, filename + '.xlsx');
  });
}