import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OpperetState, GmoStudy } from "../../types/gmo";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState: OpperetState = {
  currentStudy: null,
  isStudyLoading: false,
  error: null
};

const opperetSlice = createSlice({
  name: 'opperet',
  initialState,
  reducers: {
    setCurrentStudy: (state, action: PayloadAction<GmoStudy | null>) => {
      state.currentStudy = action.payload;
      state.error = null;
    },
    setStudyLoading: (state, action: PayloadAction<boolean>) => {
      state.isStudyLoading = action.payload;
    },
    setStudyError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.currentStudy = null;
    },
    resetStudyState: (state) => {
      state.currentStudy = null;
      state.isStudyLoading = false;
      state.error = null;
    }
  }
});

const persistConfig = {
  key: "opperet",
  storage,
  whitelist: ["currentStudy"]
};

export const { 
  setCurrentStudy, 
  setStudyLoading, 
  setStudyError,
  resetStudyState 
} = opperetSlice.actions;

const OpperetReducer = persistReducer(persistConfig, opperetSlice.reducer);

export default OpperetReducer;
