import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class WeightingModel extends Model {
    private prefix = "opperet/weightings"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getAll(params: any) {
        let path = this.prefix
        return this.withParams(path, params)
    }

    getByCriterion(criterionId: number) {
        return this.get(`${this.prefix}/by-criterion/${criterionId}`)
    }

    add(data: {}) {
        return this.post(this.prefix + `/save-add`, data)
    }

    find(id: bigint) {
        return this.get(this.prefix + `/find/${id}`)
    }

    update(id: bigint, data: {}) {
        return this.post(this.prefix + `/save-edit/${id}`, data)
    }

    delete(id: bigint) {
        return this.get(this.prefix + `/delete/${id}`)
    }
}

export default WeightingModel